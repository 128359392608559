<template>
  <footer>
    <div class="columns">
      <div class="column">
        <a href="/">KABINET</a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AuthFooter',
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';

footer {
  background-color: $authFooter;
  grid-area: footer;
  color: $white;
  padding: 0.75rem 20px;

  a {
    color: $white;
    display: block;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 5px;

    &:hover {
      text-decoration: underline;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  h6 {
    color: $white;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 5px;
  }

  p {
    font-size: 12px;
    line-height: 16px;
    color: #ccd9e4;
  }
}
</style>
